@font-face {
  font-family: 'MatterSQ-Medium';
  src: local('MatterSQ-Medium'),
    url(./fonts/MatterSQ-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'MatterSQ-Regular';
  src: local('MatterSQ-Regular'),
    url(./fonts/MatterSQ-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'MatterSQ-SemiBold';
  src: local('MatterSQ-SemiBold'),
    url(./fonts/MatterSQ-SemiBold.woff2) format('woff2');
}

html {
  font-family: 'MatterSQ-Regular', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'MatterSQ-Regular', sans-serif;
  }
}

h1,
h2,
h3 {
  font-family: 'MatterSQ-Medium', sans-serif;
  letter-spacing: 0.003em;
}

body {
  margin: 0;
  font-family: 'MatterSQ-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  color: #131313;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:before {
  width: 100vw;
  height: 200vh;
  position: fixed;
  top: 0;
  left: 0;
  content: ' ';
  background-image: url("/public/bg.png");
  background-size: cover;
  z-index: -1;
}

p {
  line-height: 1.4;
}

a {
  color: #2172e5;
  text-decoration: none;
  word-break: break-word;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.featured a {
  color: #0f0f0f;
  text-decoration: none;
}

.featured .card:hover {
  scale: 1.01;
}

.button {
  background-color: #010101;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.65rem;
  color: white;
  font-size: 14px;
  scale: 1;
  transform: all 0.3s ease;
}

.button.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.button:hover {
  scale: 1.01;
}

.replace {
  width: 16px;
  height: 16px;
}

/* utils */

.flex-between {
  display: flex;
  width: 100%;
  max-width: 960px;
  justify-content: space-between;
  align-items: center;
}

.reset {
  padding: 0;
  margin: 0;
}

.grid {
  display: grid;
  grid-gap: 0.5rem;
}

@media screen and (max-width: 960px) {
  .home-content {
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 24px;
    padding: 0 1.5rem;
  }

  .home-content .featured {
    padding: 2rem 0 2rem 0;
  }
  .featured {
    padding: 0 0 2rem 0;
  }
  .hide-small {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.loader {
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
